document.addEventListener('DOMContentLoaded', function(){

  //console.log(gon.follows);

  gon.follows.forEach(function(i){
    // console.log(i.item_id)
    // console.log(i.item_type)

    text_obj = $(`.v-js-follow.v-text[data-item-id="${i.item_id}"][data-item-type="${i.item_type}"]`)

    text_obj.removeClass("btn-orange");
    text_obj.addClass("btn-red");
    text_obj.text("已追蹤")

    icon_obj = $(`.v-js-follow.v-icon[data-item-id="${i.item_id}"][data-item-type="${i.item_type}"] i`)
    icon_obj.removeClass("icon-follow")
    icon_obj.addClass("icon-unfollow")

  })

})