import flatpickr from 'flatpickr'
import "flatpickr/dist/l10n/zh-tw"

document.addEventListener('DOMContentLoaded', function(){
	flatpickr.localize(flatpickr.l10ns.zh_tw);

  flatpickr(".datepicker");

  $(".btn-datepicker").each(function(){
    let date = JSON.parse($(this).attr('data-enableDates'));
    let url = new URL($(this).attr('data-url'))

    flatpickr(this, {
      enable: date,
      onChange: function(selectedDates, dateStr, instance) {

        var searchParams = new URLSearchParams([
          ["mdate", dateStr]
          ]);

        url.search = searchParams
        location.href = url.href
      },
    });    
  })


  flatpickr(".datetimepicker", {
    enableTime: true,
    time_24hr: true
  })

  flatpickr(".daterange", {
    mode: "range",
    maxDate: "today",
    dateFormat: "Y/m/d",
    locale: {
     rangeSeparator: '-'
   }
 });


  flatpickr(".daterangepicker", {
    mode: "range",
    maxDate: "today",
    dateFormat: "Y/m/d",
    locale: {
     rangeSeparator: '-'
   }
 });


})
