require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

require("trix")
require("@rails/actiontext")

window.jQuery = $
window.$ = $

import "lodash";

import axios from 'axios';

import 'bootstrap/dist/js/bootstrap'
import 'bootstrap/dist/css/bootstrap'
import "jquery-ui";

require("local-time").start()
//import 'data-confirm-modal'

import '../javascript/noty';
import '../javascript/bootstrap';
import '../javascript/select2';
import '../javascript/flatpickr'
import '../javascript/fancybox';
import '../javascript/bootstrap-datepicker';
import '../theme/transparent/assets/plugins/slick/slick'
import '../javascript/dropify';
import '../javascript/lazyload';
import '../javascript/datatables';
import '../javascript/follow';

import "../stylesheets/home";
//import "@fortawesome/fontawesome-free/css/all"

document.addEventListener("DOMContentLoaded", function() {
  document.querySelectorAll("action-text-attachment").forEach(function(i){
    let src = i.getAttribute("url");
    if(src.indexOf('img.youtube.com/vi/') >= 0){
      let youtube_id = src.split("img.youtube.com/vi/").pop().split("/0.jpg")[0];
      let embed = new DOMParser().parseFromString(`<iframe width="100%" height="315" src="https://www.youtube.com/embed/${youtube_id}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`, 'text/html').body.firstChild;
      i.replaceWith(embed);
    };
  })
});

$(document).ready(function() {

  $('.js-long_short_equity_switch').click(function() {
    // 判断 checkbox 是否被选中
    if ($(this).is(':checked')) {
      // 空
      $( $(this).data('short') ).addClass('show active');
      $( $(this).data('long') ).removeClass('show active');
    } else {
      // 多
      $( $(this).data('long') ).addClass('show active');
      $( $(this).data('short') ).removeClass('show active');
    }
  });

});  


$(document).ready(function() {
  // scroll to top
  $(window).scroll(function(){
    scrollFunction();
  })
  function scrollFunction() {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      $("#btn-totop").fadeIn();
    } else {
      $("#btn-totop").fadeOut();
    }
  }
  $("#btn-totop").click(function(){
    $('html, body').animate({
      scrollTop: 0
    }, 100)
  })

});