import DataTable from 'datatables.net-dt';
import 'datatables.net-bs5'
//import 'datatables.net-dt/css/jquery.dataTables.css';
window.DataTable = DataTable;
document.addEventListener("DOMContentLoaded", function() {

  if (document.querySelectorAll(".myDataTable")){
    let table = new DataTable('.myDataTable', {
      searching: false,
      ordering:  false,
      bLengthChange: false,
      language: {
        url: "/datatable/zh-TW.json"
      },
      "sDom": "<<'table-responsive't><'justify-content'p>>",
    });    
  }

});